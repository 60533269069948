#hero {
    background-color: #FFFCF7;
    padding-bottom: 101px;
}

#hero .hero-contents {
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
}

#hero .hero-contents .hero-left-side {
    padding-top: 31px;
}

#hero h1 {
    font-family: 'Oswald';
    font-style: normal;
    font-size: 75px;
    line-height: 97px;
    letter-spacing: -0.03em;
    color: #1B1C31;
}

#hero .get-smarter-text {
    font-weight: 400;
}

#hero .nft-text {
    color: #E27C23;
    font-weight: 600;
}

#hero .investment-text {
    font-weight: 700;
}

#hero p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    color: #3A3A3A;
}

#hero .newsletter-text {
    margin: 42px 0 44px 0;
}

#hero .hero-input-container {
    background: #FFFFFF;
    box-shadow: 1px 4.35199px 100px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    max-width: 581px;
    padding: 12px 13px 13px 22px;
    height: 90px;
}

#hero .hero-input {
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    border: none;
    outline: none;

}

#hero .hero-input::placeholder {
    color: #000000;
}


#hero .hero-input-btn {
    background: #1B1C31;
    border-radius: 10px;
    padding: 15px 65px 20px 62px;
    font-family: 'Readex Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
    border: 1px solid #1B1C31;
}

#hero .hero-input-btn:hover {
    color: #1B1C31;
    background-color: white;
}


#hero .read-by-nft-text {
    margin: 45px 0 24px 0;
}

#hero .hero-bottom-image-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

#hero .hero-bottom-image-group img {
    margin: 0 -13px;
}






@media (max-width:1440px) {
    #hero .hero-contents .hero-left-side {
        width: 50%;
    }

    #hero .hero-bottom-image-group img {
        width: 170px;
    }

    #hero h1 {
        font-size: 65px;
        line-height: 87px;
    }

    #hero .hero-input-container {
        height: 70px;
    }

    #hero .hero-input-btn {
        padding: 15px 45px 20px 42px;
    }

    #hero .hero-input {
        width: 50%;
    }

    #hero .right-side img {
        width: 90%;
    }
}


@media (max-width:1200px) {
    #hero {
        background-color: #FFFCF7;
        padding-bottom: 51px;
    }

    #hero h1 {
        font-size: 55px;
        line-height: 67px;
    }

    #hero p {
        font-size: 20px;
        line-height: 28px;
    }

    #hero .newsletter-text {
        margin: 30px 0;
    }

    #hero .hero-bottom-image-group img {
        width: 100px;
        margin: 0;
    }

    #hero .hero-input-container {
        height: 50px;
    }

    #hero .hero-input-btn {
        padding: 5px 15px;
        font-size: 18px;
        line-height: 24px;
    }
}


@media (max-width:991px) {
    #hero h1 {
        font-size: 35px;
        line-height: 47px;
    }

    #hero .hero-bottom-image-group img {
        width: 120px;
    }

    #hero p {
        font-size: 18px;
        line-height: 24px;
    }

    #hero .hero-input {
        font-size: 16px;
        line-height: 18px;
    }

    #hero .read-by-nft-text {
        margin: 15px 0;
    }
}



@media (max-width:768px) {
    #hero h1 {
        font-size: 45px;
    }

    #hero .hero-contents .hero-left-side {
        width: 100%;
    }

    #hero .right-side img {
        max-width: 612px;
        display: block;
        margin: 0 auto;
    }


}