#footer {
    background-color: #FFFCF7;
}

.footer-container {
    max-width: 1425px;
    margin: 0 auto;
}


#footer .footer-top {
    padding: 54px 0 32.5px 0;
    border-bottom: 1px solid rgba(27, 28, 49, 0.3);
    margin-bottom: 17.5px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 40px;
}

#footer .footer-top h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 31px;
    color: #1B1C31;
    margin-bottom: 13.73px;
}



#footer .link-group-item ul {
    list-style: none;
    padding: 0;
}

#footer .link-group-item ul li a {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 50px;
    color: #1B1C31;
    opacity: 0.8;
    text-decoration: none;
}

#footer .footer-social-group {
    gap: 21px;
}


#footer .footer-bottom-text {
    padding: 27px 0;
}

#footer .footer-bottom-text p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #1B1C31;
    opacity: 0.8;
    margin: 0;
}

#footer .footer-bottom-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}


#footer .footer-bottom-text .link-group {
    display: flex;
    gap: 25px;
}

#footer .footer-bottom-text .link-group a {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #1B1C31;
    text-decoration: none;
    opacity: 0.8;
}


@media (max-width:1440px) {
    .footer-container {
        padding: 0 3rem;
    }
}


@media (max-width:1024px) {
    #footer .footer-top {
        grid-template-columns: repeat(4, 1fr);
    }

    #footer .link-group-item ul li a {
        font-size: 19px;
        line-height: 35px;
    }


}

@media (max-width:991px) {
    #footer .footer-bottom-text {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

@media (max-width:768px) {
    .footer-container {
        padding: 0 2rem !important;
    }

    #footer .footer-top {
        grid-template-columns: repeat(3, 1fr);
    }

    #footer .footer-social-group {
        gap: 11px;
    }

    #footer .footer-bottom-text .link-group {
        display: flex;
        gap: 15px;
    }

    #footer .footer-bottom-text .link-group a {
        font-size: 12px;
        line-height: 20px;
    }

    #footer .footer-bottom-text p {
        font-size: 17px;
    }
}

@media (max-width:425px) {
    .footer-container {
        padding: 0 0.5rem !important;
    }

    #footer .footer-top {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 0 1rem;
    }

    #footer .footer-social-group {
        gap: 2px;
    }

    #footer .footer-bottom-text .link-group {
        display: flex;
        gap: 7px;
    }

    #footer .footer-bottom-text p {
        font-size: 16px;
        text-align: center;
        display: block;
    }
}