#trust {
    padding: 55px 0 0px 0;
}


#trust h1 {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 80px;
    letter-spacing: -0.03em;
    color: #1B1C31;
    text-align: center;
    margin-bottom: 65px;
}

#trust .trust-contents {
    gap: 100px;
    justify-content: space-between;
    align-items: center;
    padding-right: 29px;
    background-image: url("/public/images/bg.png");
    background-size: auto;
    background-position: right bottom;
    background-repeat: no-repeat;
    padding-bottom: 68px;
}

#trust .trust-contents p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 39px;
    letter-spacing: -0.03em;
    color: #3A3A3A;
    margin: 23px 0;
}

#trust .trust-contents .right {
    max-width: 593px;
}

#trust h4 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    color: #E27C23;
    margin: 53px 0 0 0;
}

#trust h5 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #171717;
    margin: 0;
}


#trust .carousel-control-next-icon {
    position: absolute;
    bottom: 30px;
    right: 100px;
}

#trust .carousel-control-prev-icon {
    position: absolute;
    bottom: 30px;
    right: -320px;
}

.carousel-indicators {
    display: none !important;
}


@media (max-width:1440px) {
    #trust .trust-contents .left {
        max-width: 50%;
    }

    #trust .trust-contents .right {
        max-width: 50%;
    }

    #trust .trust-contents {
        gap: 60px;
        padding-bottom: 58px;
    }
}

@media (max-width:1275px) {
    #trust .carousel-control-next-icon {
        position: absolute;
        bottom: 40px;
        right: 110px;
    }

    #trust .carousel-control-prev-icon {
        position: absolute;
        bottom: 40px;
        right: -220px;
    }

    #trust .trust-contents {
        background-size: 70%;
    }

    #trust .trust-contents p {
        font-size: 18px;
        line-height: 28px;
        margin: 17px 0;
    }

    #trust h4 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #E27C23;
        margin: 23px 0 0 0;
    }
}


@media (max-width:1075px) and (min-width:768px) {
    #trust .qoute {
        width: 50px;
    }

    #trust .stars img {
        width: 24px;
    }


}

@media (max-width:1075px) {
    #trust .carousel-control-next-icon {
        position: absolute;
        bottom: 30px;
        right: 50px;
    }

    #trust .carousel-control-prev-icon {
        position: absolute;
        bottom: 30px;
        right: -200px;
    }

    #trust h1 {
        font-size: 35px;
        line-height: 60px;
    }



}

@media (max-width:900px) {
    #trust .carousel-control-next-icon {
        position: absolute;
        bottom: 30px;
        right: 50px;
    }

    #trust .carousel-control-prev-icon {
        position: absolute;
        bottom: 30px;
        right: -160px;
    }
}

@media (max-width:768px) {
    #trust .trust-contents {
        flex-direction: column;
    }

    #trust .trust-contents .left {
        max-width: 100%;
    }

    #trust .trust-contents .right {
        max-width: 100%;
    }

    #trust .carousel-control-next-icon {
        position: absolute;
        bottom: 30px;
        right: 50px;
    }

    #trust .carousel-control-prev-icon {
        position: absolute;
        bottom: 30px;
        right: -360px;
    }

    #trust .trust-contents {
        background-size: 100%;
    }
}

@media (max-width:660px) {
    #trust .carousel-control-next-icon {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }

    #trust .carousel-control-prev-icon {
        position: absolute;
        bottom: 30px;
        right: -260px;
    }
}

@media (max-width:525px) {
    #trust .qoute {
        width: 40px;
    }

    #trust h1 {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 30px;
    }

    #trust .carousel-control-next-icon {
        position: absolute;
        bottom: -70px;
        right: 50px;
    }

    #trust .carousel-control-prev-icon {
        position: absolute;
        bottom: -70px;
        right: -90px;
    }

}